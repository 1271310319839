export default function getStatusText(statusId){
    let statusText;
    switch (statusId) {
        case 1:
            statusText = "PENDIENTE";
            break;
        case 2:
            statusText = "EN REVISIÓN";
            break;
        case 3:
            statusText = "AUTORIZADA";
            break;
        case 4:
            statusText = "NO APLICA";
            break;
        case 5:
            statusText = "CERRADA";
            break;
        default:
            statusText = "";
            break;
    }
    return statusText;
};
