import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline/index";
import Container from "@material-ui/core/Container/index";
import ClarificationsList from "../../components/clarifications/list/all";

class AllClarifications extends React.PureComponent{
    render() {

        return(
            <div>
                <React.Fragment>
                    <CssBaseline/>
                    <Container maxWidth="xl" className={"mt-2"}>
                        <ClarificationsList user={this.props.match.params.user}/>
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}

export default AllClarifications;
