import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from "@material-ui/core/CircularProgress";
import Services from "../../../services/certifications/Services";
import {Divider} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';

class CertificationMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mediaFiles: [],
            isLoadMaterial: false
        };
    }

    componentDidMount() {
        this.setState({isLoadMaterial: true});
        this.getCertificationMaterial();
    }

    getCertificationMaterial = () => {
        let url = "/certifications/" + this.props.certificationId + "/material";
        Services.get(url)
            .then(response => {
                let code = response.code;
                if (code === 200) {
                    let materials = JSON.parse(response.data);
                    this.setState({mediaFiles: materials});
                } else {
                    console.log("error");
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoadMaterial: false}))
    };

    handleClickMaterial = (material) => {
        let currentMaterial = this.state.mediaFiles;
        let newMediaFiles = [];
        for (let i = 0; i < currentMaterial.length; i++) {
            let m = currentMaterial[i];
            if (m.fileId === material.fileId) {
                m.isReviewed = true;
            }
            newMediaFiles.push(m);
        }
        this.setState({
            mediaFiles: newMediaFiles
        });

        this.validateActivateNextStepButton();
    };

    validateActivateNextStepButton = () => {
        let isReviewedAll = true;
        for (let i = 0; i < this.state.mediaFiles.length; i++) {
            let m = this.state.mediaFiles[i];
            isReviewedAll = isReviewedAll && m.isReviewed;
        }

        if (isReviewedAll) {
            this.props.handleActivateButtonNextStep();
        }
    };

    render() {

        return (
            <div>
                <div className={"text-center"} hidden={!this.state.isLoadMaterial}>
                    <CircularProgress/>
                </div>
                <div hidden={this.state.isLoadMaterial}>
                    <div className={"row col-md-12"}>
                        <h6>Lee y/o visualiza cuidadosamente el siguiente material de capacitación para realizar tu
                            evaluación de certificación</h6>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-4"}></div>
                        <div className={"col-md-4"}>

                            <List dense={true}>
                                {
                                    this.state.mediaFiles.map((material, index) => {
                                        return (
                                            <div key={"div" + index}>
                                                <ListItem key={index}>
                                                    <ListItemText
                                                        primary={material.description}
                                                        secondary={material.format}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end"
                                                                    aria-label="delete"
                                                                    href={material.url}
                                                                    target={"_blank"}
                                                                    onClick={() => {
                                                                        this.handleClickMaterial(material)
                                                                    }}
                                                        >
                                                            {
                                                                material.format === "mp4" ? material.isReviewed ?
                                                                    <CheckIcon style={{color:"green"}}/> : <PlayArrowIcon color={"primary"}/> :
                                                                    material.isReviewed ? <CheckIcon style={{color:"green"}}/> :
                                                                        <VisibilityIcon color={"primary"}/>
                                                            }
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider light/>
                                            </div>
                                        )
                                    })
                                }
                            </List>
                        </div>
                        <div className={"col-md-4"}></div>
                    </div>
                </div>
            </div>
        );
    }

}

export default CertificationMaterial;
