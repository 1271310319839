import APICertifications from './config';

const Services = {
    get: (url) => new Promise(
        (resolve, reject) => {
            APICertifications.get(url)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    ),
    post: (url, data) => new Promise(
        (resolve, reject) => {
            APICertifications.post(url, data)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    ),
    put: (url, data) => new Promise(
        (resolve, reject) => {
            APICertifications.put(url, data)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    )
};

export default Services;
