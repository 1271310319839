import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import FullScreenDialog from '../../util/dialog/full-screen';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import ClarificationService from "../../../services/clarifications/ClarificationService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "../../util/dialog/modal";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import WorkIcon from '@material-ui/icons/Work';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Tooltip from "@material-ui/core/Tooltip";
import MessageIcon from '@material-ui/icons/Message';
import FileList from "../../util/common/file-list";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Constants from "../../../util/Constants";
import S3FileUpload from 'react-s3';
import SnackbarContentWrapper from "../../util/common/snack-bar";
import Snackbar from "@material-ui/core/Snackbar";

class AddClarification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestingUserId: props.user,
            openSnackbar: false,
            messageSnackbar: "",
            typeSnackbar: "error",
            isSaving: false,
            previewEvidence: {
                isOpen: false,
                image: "",
                name: ""
            },
            modal: {
                openModal: false,
                title: '',
                text: '',
                field: [],
                buttons: []
            },
            loading: false,
            disabledSearch: false,
            openFullScreenDialog: false,
            assignmentId: '',
            projectId: '',
            project: '',
            locationId: '',
            location: '',
            upaxerId: '',
            upaxer: '',
            tasks: [],
            showInfo: false
        };
    }

    handleOpenFullScreenDialog = () => {
        this.setState({
            openFullScreenDialog: true
        });
    };
    handleCloseFullScreenDialog = () => {
        this.cleanForm();
        this.setState({
            openFullScreenDialog: false
        });
        this.props.handleLoadData(this.state.requestingUserId);
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    handleSearchInformation = () => {
        if (this.state.assignmentId > 0) {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    disabledSearch: true,
                    showInfo: false
                });
                let url = "/assignments/" + this.state.assignmentId;
                ClarificationService.get(url)
                    .then(data => {
                        let code = data.status;
                        let informationArray = JSON.parse(data.body.data);
                        if (code === 200) {
                            if (informationArray.length > 0) {
                                let tasks = [];
                                let task;
                                let item;
                                for (let i = 0; i < informationArray.length; i++) {
                                    item = informationArray[i];
                                    task = {
                                        detailTaskId: item.detailTaskId,
                                        taskId: item.taskId,
                                        taskName: item.taskName.toUpperCase(),
                                        auditDate: item.auditDate,
                                        confirmDate: item.confirmDate,
                                        comment: '',
                                        checked: false,
                                        enabled: item.enabled,
                                        evidences: [],
                                        auditStatus: item.auditStatus
                                    };
                                    tasks.push(task);
                                }

                                let info = informationArray[0];
                                this.setState({
                                    projectId: info.projectId,
                                    project: info.project.toUpperCase(),
                                    locationId: info.locationId,
                                    location: info.location.toUpperCase(),
                                    upaxer: info.upaxer.toUpperCase(),
                                    upaxerName: info.upaxerName.toUpperCase(),
                                    tasks: tasks,
                                    showInfo: true,
                                    activeComment: false
                                });
                                this.resetSearchButton();
                            } else {
                                let message = 'La asignación ingresada no fue encontrada, recuerda que las aclaraciones solo aplican para tareas aceptadas confirmadas o rechazadas confirmadas.';
                                this.openSnackbar(message, Constants.SNACKBAR_ERROR_TYPE);
                                this.resetSearchButton();
                            }

                        } else {
                            throw new Error(informationArray.message);
                        }
                    })
                    .catch(error => {
                        let message = "Ocurrió un erro al solicitar la información: " + error;
                        this.openSnackbar(message, Constants.SNACKBAR_ERROR_TYPE);
                        this.resetSearchButton();
                    });
            }
        } else {
            this.openSnackbar("Ingresa un número de asignación", Constants.SNACKBAR_ERROR_TYPE);
        }

    };

    showModal = (title, text) => {
        this.setState({
            modal: {
                openModal: true,
                title: title,
                text: text
            }
        });
    };

    resetSearchButton = () => {
        this.setState({
            loading: false,
            disabledSearch: false,
        });
    };

    validateData = () => {
        let isAssignmentCorrect = this.state.assignmentId > 0;
        let validate = {
            success: true
        };
        if (!isAssignmentCorrect) {
            validate = {
                success: false,
                title: '¡Error!',
                text: 'Ingresa un número de asignación y presiona el botón buscar'
            };
        } else if (!this.state.showInfo) {
            validate = {
                success: false,
                title: '¡Error!',
                text: 'Realiza la búsqueda de información para realizar la solicitud'
            };
        } else {
            let tasks = this.state.tasks;
            let isSelectedTask;
            let task;
            let count = 0;
            for (let i = 0; i < tasks.length; i++) {
                task = tasks[i];
                isSelectedTask = task.checked;
                if (isSelectedTask && task.enabled) {
                    count++;
                    let comment = task.comment.trim();
                    if (comment.length < 10) {
                        validate = {
                            success: false,
                            title: '¡Error!',
                            text: 'Ingresa un comentario para la tarea:' + task.taskName
                        };
                        break;
                    }
                }
                if (i + 1 >= tasks.length && count === Constants.ZERO) {
                    validate = {
                        success: false,
                        title: '¡Error!',
                        text: 'Debes seleccionar al menos una tarea'
                    };
                }
            }
        }

        return validate;
    };

    handleSaveClarification = async () => {
        let validate = this.validateData();
        let success = validate.success;
        if (success) {
            this.setState({isSaving: true});
            try {
                let parameters = {
                    projectId: this.state.projectId,
                    assignmentId: this.state.assignmentId,
                    requestingUserId: this.state.requestingUserId
                };
                let tasks = this.state.tasks;
                let isSelectedTask;
                let task;
                let errors = [];
                for (let i = 0; i < tasks.length; i++) {
                    task = tasks[i];
                    isSelectedTask = task.checked;
                    if (isSelectedTask && task.enabled) {
                        parameters.detailTaskId = task.detailTaskId;
                        parameters.comment = task.comment;
                        let response = await this.saveClarification(parameters);
                        let code = response.status;
                        if (code === 200){
                            let clarificationId = response.body.data;
                            let evidences = task.evidences;
                            for (let x = 0; x < evidences.length; x++) {
                                let evidence = evidences[x];
                                let dirName = parameters.assignmentId + "/" + task.detailTaskId;
                                let responseFromS3 = await this.uploadEvidencesToS3(dirName, evidence);
                                let evidenceToDB = {
                                    clarificationId: clarificationId,
                                    fileName: evidence.name,
                                    url: responseFromS3.location,
                                    format: evidence.type,
                                    userModified: this.state.requestingUserId
                                };
                                await this.saveEvidence(evidenceToDB);
                            }
                        }else {
                            errors.push(task.taskName);
                        }
                    }
                }

                if (errors.length === tasks.length) {
                    throw new Error("Por favor intentalo nuevamente");
                }else if (errors.length > 0){
                    let errorsList = "";
                    for (let x = 0; x < errors.length; x++) {
                        errorsList = errorsList + errors[x] + "\n";
                    }
                    throw new Error("\nLas siguientes tareas no fueron almacenadas:\n" + errorsList);
                } else {
                    let message = 'La solicitud fue almacenada correctamente';
                    this.openSnackbar(message, Constants.SNACKBAR_SUCCESS_TYPE);
                    this.cleanForm();
                }


            } catch (e) {
                let message = 'Ocurrió un error al almacenar la solicitud: ' + e;
                this.openSnackbar(message, Constants.SNACKBAR_ERROR_TYPE);
            } finally {
                this.setState({isSaving: false});
            }
        } else {
            this.openSnackbar(validate.text, Constants.SNACKBAR_ERROR_TYPE);
        }

    };

    saveClarification = (parameters) => {
        let url = "/clarifications";
        return ClarificationService.post(url, parameters);
    };

    uploadEvidencesToS3 = (dirName, evidence) => {
        let file = evidence.file;
        const config = {
            bucketName: Constants.BUCKET_NAME_TO_EVIDENCES,
            dirName: dirName,
            region: Constants.REGION,
            accessKeyId: Constants.ACCESS_KEY,
            secretAccessKey: Constants.SECRET_KEY
        };
        return S3FileUpload.uploadFile(file, config);
    };

    saveEvidence = (evidence) => {
        let url = "/clarifications/evidences";
        return ClarificationService.post(url, evidence);
    };

    handleCloseModal = () => {
        this.setState({
            modal: {
                openModal: false,
                title: '',
                text: ''
            }
        });
    };

    cleanForm = () => {
        this.setState({
            loading: false,
            disabledSearch: false,
            assignmentId: '',
            projectId: '',
            project: '',
            locationId: '',
            location: '',
            upaxerId: '',
            upaxer: '',
            tasks: [],
            showInfo: false
        });


    };

    handleSelectTask = detailTaskId => () => {
        let newTasks = this.state.tasks;
        let task = newTasks.find(task => task.detailTaskId === detailTaskId);
        let currentChecked = task.checked;
        newTasks.find(task => task.detailTaskId === detailTaskId).checked = !currentChecked;
        newTasks.find(task => task.detailTaskId === detailTaskId).activeComment = !currentChecked;
        if (!currentChecked) {
            newTasks.find(task => task.detailTaskId === detailTaskId).comment = "";
            newTasks.find(task => task.detailTaskId === detailTaskId).evidences = [];
        }
        this.setState({tasks: newTasks});
    };

    handleChangeComment = detailTaskId => event => {
        let tempTasks = this.state.tasks;
        tempTasks.find(task => task.detailTaskId === detailTaskId).comment = event.target.value;
        this.setState({tasks: tempTasks});
    };
    handleLoadEvidence = detailTaskId => ({target}) => {
        let file = target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            let evidence = {
                name: file.name,
                type: file.type,
                stream: e.target.result,
                file: file
            };
            let tempTasks = this.state.tasks;
            tempTasks.find(task => task.detailTaskId === detailTaskId).evidences.push(evidence);
            this.setState({tasks: tempTasks});
        };
    };

    handleDeleteEvidence = (detailTaskId, index) => () => {
        let tempTasks = this.state.tasks;
        tempTasks.find(task => task.detailTaskId === detailTaskId).evidences.splice(index, 1);
        this.setState({tasks: tempTasks});
    };

    handleVisualizeEvidence = (detailTaskId, index) => () => {
        let tempTasks = this.state.tasks;
        let evidence = tempTasks.find(task => task.detailTaskId === detailTaskId).evidences[index];
        let stream = evidence.stream;
        let name = evidence.name;
        this.setState({
            previewEvidence: {
                isOpen: true,
                image: stream,
                name: name
            }
        });

    };
    handleCloseVisualizeEvidence = () => {
        this.setState({
            previewEvidence: {
                isOpen: false,
                image: ""
            }
        });
    };

    handleCloseSnackbar = () => {
        this.setState({
            openSnackbar: false
        });
    };

    openSnackbar = (message, type) => {
        this.setState({
            openSnackbar: true,
            typeSnackbar: type,
            messageSnackbar: message
        });
    };

    render() {
        let lightbox;
        if (this.state.previewEvidence.isOpen) {
            lightbox = (
                <Lightbox
                    mainSrc={this.state.previewEvidence.image}
                    onCloseRequest={this.handleCloseVisualizeEvidence}
                    reactModalStyle={{zIndex: 999}}
                    imageTitle={this.state.previewEvidence.name}
                />
            );
        }
        return (
            <div className={"mb-4"}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.typeSnackbar}
                        message={this.state.messageSnackbar}
                    />
                </Snackbar>

                <Modal
                    open={this.state.modal.openModal}
                    handleCloseModal={this.handleCloseModal}
                    title={this.state.modal.title}
                    text={this.state.modal.text}
                />
                {lightbox}
                <Tooltip title={"Nueva aclaración"} placement="right">
                    <Fab size="small"
                         color="primary"
                         aria-label="add"
                         onClick={this.handleOpenFullScreenDialog}>
                        <AddIcon/>
                    </Fab>
                </Tooltip>
                <FullScreenDialog open={this.state.openFullScreenDialog}
                                  isSaveButtonshow={!this.state.isSaving}
                                  title={"Nueva aclaración"}
                                  saveLabel={"Guardar"}
                                  handleCloseFullScreenDialog={this.handleCloseFullScreenDialog}
                                  handleSave={this.handleSaveClarification}
                                  isSaving={this.state.isSaving}
                >
                    <div className={"mt-4 ml-4"}>
                        <div className={"row col-md-12"}>
                            <TextField
                                id="assignmentId"
                                label="Asignación"
                                value={this.state.assignmentId}
                                onChange={this.handleChange('assignmentId')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                helperText="Ingresa el id de asignación y da click en el botón buscar"
                                InputProps={{
                                    endAdornment: (
                                        <Tooltip title="Buscar" placement="right">
                                            <InputAdornment position="end">
                                                <IconButton color="inherit" aria-label="close" edge="end"
                                                            onClick={this.handleSearchInformation}
                                                            hidden={this.state.disabledSearch}>
                                                    <SearchSharpIcon/>
                                                </IconButton>
                                                {this.state.loading && <CircularProgress size={30}/>}
                                            </InputAdornment>
                                        </Tooltip>
                                    ),
                                }}
                            />
                        </div>
                        <Grow in={this.state.showInfo}>
                            <div className={"row"}>
                                <div className={"col-md-4"}>
                                    <ListItem component={ListItem} key={"project"}>
                                        <ListItemAvatar>
                                            <WorkIcon/>
                                        </ListItemAvatar>
                                        <ListItemText primary={this.state.project}
                                                      secondary={this.state.projectId}/>
                                    </ListItem>
                                </div>
                                <div className={"col-md-4"}>
                                    <ListItem component={ListItem} key={"location"}>
                                        <ListItemAvatar>
                                            <LocationOnIcon/>
                                        </ListItemAvatar>
                                        <ListItemText primary={this.state.location}
                                                      secondary={this.state.locationId}/>
                                    </ListItem>
                                </div>
                                <div className={"col-md-4"}>
                                    <ListItem component={ListItem} key={"upaxer"}>
                                        <ListItemAvatar>
                                            <PersonIcon/>
                                        </ListItemAvatar>
                                        <ListItemText primary={this.state.upaxerName}
                                                      secondary={this.state.upaxer}/>
                                    </ListItem>
                                </div>
                            </div>
                        </Grow>
                        <Grow in={this.state.showInfo}>
                            <div className={"row col-md-12"}>
                                <ListItem component={ListItem} key={"tasksList"}>
                                    <ListItemAvatar>
                                        <FormatListBulletedIcon/>
                                    </ListItemAvatar>
                                    <ListItemText primary={"TAREAS"} secondary={"SELECCIONA LAS TAREAS"}/>
                                </ListItem>
                            </div>
                        </Grow>
                    </div>
                    <div className={"ml-4 mr-4"}>
                        <Grow in={this.state.showInfo}>
                            <List component={List}>
                                {
                                    this.state.tasks.map((task, index) => {
                                        return (
                                            <div key={"div-" + index}
                                                 style={{borderLeft: task.auditStatus === 2 ? "5px solid green" : "5px solid red"}}>
                                                <div className={"row col-md-12"}>
                                                    <ListItem key={"ListItem-" + index} role={undefined} dense
                                                              button
                                                              disabled={!task.enabled}
                                                              onClick={this.handleSelectTask(task.detailTaskId)}
                                                              component={ListItem}

                                                    >
                                                        <ListItemIcon key={"ListItemIcon-" + index}>
                                                            {task.enabled ? <Checkbox
                                                                edge="start"
                                                                checked={task.checked}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{'aria-labelledby': task.taskId}}
                                                            /> : ""}

                                                        </ListItemIcon>
                                                        <ListItemText key={"ListItemText-" + index}
                                                                      primary={task.taskName}
                                                                      secondary={
                                                                          <React.Fragment>
                                                                              <Typography
                                                                                  component="span"
                                                                                  variant="body2"
                                                                                  color="textPrimary"
                                                                              >
                                                                                  Auditoria
                                                                              </Typography>
                                                                              {" — " + task.auditDate}
                                                                              <br/>
                                                                              <Typography
                                                                                  component="span"
                                                                                  variant="body2"
                                                                                  color="textPrimary"
                                                                              >
                                                                                  Confirmación
                                                                              </Typography>
                                                                              {" — " + task.confirmDate}
                                                                          </React.Fragment>
                                                                      }

                                                        />
                                                    </ListItem>
                                                </div>

                                                <div className={"row"}
                                                     key={"Grow-div-" + index}
                                                     style={{
                                                         marginLeft: 0
                                                     }}
                                                >
                                                    <div className={"col-md-6"}
                                                         key={"Grow-div-div-" + index}
                                                         style={{
                                                             display: task.activeComment ? 'block' : 'none'
                                                         }}
                                                    >
                                                        <MessageIcon/>
                                                        <TextField
                                                            id={"comment" + index}
                                                            key={"comment" + index}
                                                            multiline
                                                            rows="8"
                                                            value={task.comment}
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                            onChange={this.handleChangeComment(task.detailTaskId)}
                                                        />
                                                    </div>
                                                    <div className={"col-md-6"}
                                                         key={"files-div" + index}
                                                         style={{
                                                             display: task.activeComment ? 'block' : 'none'
                                                         }}
                                                    >
                                                        <FileList title={"Evidencias"}
                                                                  files={task.evidences}
                                                                  id={task.detailTaskId}
                                                                  key={"FileList-" + index}
                                                                  subtitle={"Lista de evidencias"}
                                                                  onLoadFile={this.handleLoadEvidence}
                                                                  onDelete={this.handleDeleteEvidence}
                                                                  onVisualize={this.handleVisualizeEvidence}
                                                        />
                                                    </div>
                                                </div>
                                                <Divider component={Divider}/>
                                            </div>)
                                    })
                                }
                            </List>
                        </Grow>
                    </div>
                </FullScreenDialog>
            </div>);
    }
}

export default AddClarification;
