import React from 'react';
import Typography from "@material-ui/core/Typography";
import medal from "../../../static/images/arriba.png"
import sad from "../../../static/images/noaprobo.png"
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Services from "../../../services/certifications/Services";

class CertificationScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingScore: false,
            score: 0,
            name: '',
            waitTime: 0,
            remainingAttempts: 0
        };
    }

    componentDidMount() {
        this.setState({
            isLoadingScore: true
        });
        this.props.handleDesactivateButtonNextStep();
        this.getScore();
    }

    getScore = () => {
        let url = "/score/certifications/" + this.props.certificationId + "/users/" + this.props.userId;
        Services.get(url)
            .then(response => {
                let code = response.code;
                if (code === 200) {
                    let data = JSON.parse(response.data);
                    this.setState(
                        {
                            score: data.score,
                            name: data.name,
                            waitTime: data.waitTime,
                            remainingAttempts: data.attempts - data.attempted
                        }
                    );
                    this.props.handleUpdateInformation(data.certificationDate,data.score);
                    this.props.handleActivateButtonNextStep();
                } else {
                    console.log("error");
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoadingScore: false}))
    };

    render() {
        return (<div>
                <div hidden={!this.state.isLoadingScore} className={"text-center"} style={{marginTop: "3%"}}>
                    <CircularProgress/>
                </div>
                <Slide direction="left" mountOnEnter unmountOnExit
                       in={!this.state.isLoadingScore && this.state.score >= 8}>
                    <div className={"text-center"}>
                        <Typography variant="h5" component="h2">
                            {"¡Felicidades " + this.state.name + "!"}
                        </Typography>
                        <img style={{width: "150px", height: "150px"}} src={medal} alt={"Aprobado"}/>

                        <Typography variant="h5" component="h2">
                            {"Ya estás certificado"}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {"Ahora ya puedes auditar tareas de los proyectos de ésta certificación"}
                        </Typography>
                    </div>
                </Slide>
                <Slide direction="left" mountOnEnter unmountOnExit
                       in={!this.state.isLoadingScore && this.state.score < 8}>
                    <div className={"text-center"}>
                        <Typography variant="h5" component="h2">
                            {"¡Lo sentimos " + this.state.name + ", no aprobaste la certificación!"}
                        </Typography>
                        <img style={{width: "155px", height: "150px"}} src={sad} alt={"No aprobado"}/>
                        <Typography variant="h5" component="h2">
                            {"Repasa de nuevo el material de capacitación"}
                        </Typography>
                        <p>{"Para auditar tareas de los proyectos es necesario aprobar la certificación"}</p>
                        <p>{"Intentalo de nuevo en " + this.state.waitTime + " minutos"}</p>
                        <p>{"Tienes  " + this.state.remainingAttempts + " intentos más para acreditar la certificación"}</p>
                    </div>
                </Slide>
            </div>
        );
    }
}

export default CertificationScore;
