import React from 'react';
import {process} from "@progress/kendo-data-query";
import "@progress/kendo-theme-material/dist/all.css";
import {IntlProvider, load, LocalizationProvider, loadMessages} from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/es/numbers.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import esMessages from '../../../globalization/es';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);



loadMessages(esMessages, 'es-ES');

function kGridWithState(WrappedGrid, tableSetting, items, columns, group) {
    return class KGrid extends React.Component {
        constructor(props) {
            super(props);
            const dataState = {
                skip: 0,
                take: 10,
                sort: [],
                filter: {
                    logic: "and",
                    filters: []
                },
                group: group
            };
            this.state = {
                dataResult: process(items, dataState),
                dataState: dataState
            };
        }

        onDataStateChange = (event) => {
            this.setState({
                dataResult: process(items, event.data),
                dataState: event.data
            });
        };

        render() {
            return (
                <LocalizationProvider language="es-ES">
                    <IntlProvider locale="es">
                        <WrappedGrid
                            pageable={{buttonCount: 5, pageSizes: true}}
                            {...tableSetting}
                            data={this.state.dataResult}
                            {...this.state.dataState}
                            onDataStateChange={this.onDataStateChange}
                        >
                            {columns}
                        </WrappedGrid>
                    </IntlProvider>
                </LocalizationProvider>

            );
        }
    }
}

export default kGridWithState;
