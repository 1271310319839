const Constants = {
    BUCKET_NAME_TO_EVIDENCES: "qm-clarifications-evidences-prod",
    REGION: "us-east-1",
    ACCESS_KEY: "AKIAWWOA6GWZEO3APUVF",
    SECRET_KEY: "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",
    ZERO: 0,
    SNACKBAR_ERROR_TYPE: "error",
    SNACKBAR_SUCCESS_TYPE: "success",
    defaultTableSetting: {
        sortable: true,
        filterable: true,
        reorderable: true,
        groupable: false
    },
    MINIMUM_PASSING_GRADE: 8
};

export default Constants;
