import React from 'react';
import FullScreenDialog from "../../../util/dialog/full-screen";
import LoaderFullscreen from "../../../util/loader/screen";
import Grow from "@material-ui/core/Grow";
import GeneralDetail from "../general";
import CertificateDetail from "../certificate";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import {amber} from "@material-ui/core/colors";

function CertificationDetail(props) {

    let certificationArea = "";
    let isCertified = props.certificationInformation.isCertified && props.certificationInformation.isCertifiedByDates;
    if (!isCertified) {
        let remainingAttempts = props.certificationInformation.attempts - props.certificationInformation.attempted;
        if (remainingAttempts < 1 && !props.certificationInformation.fromScore) {
            certificationArea = (
                <div>
                    <br/>
                    <SnackbarContent
                        message={
                            <span id="client-snackbar">
                        <ErrorIcon/>
                                {"  Haz superado el número de intentos para aprobar la certificación, ya no podrás certificarte."}
                        </span>
                        }
                        style={{backgroundColor: "red"}}
                        elevation={4}
                    />
                </div>
            );
        } else {
            if (props.certificationInformation.waitTime > props.certificationInformation.minutesElapsed && !props.certificationInformation.fromScore) {
                certificationArea = (
                    <div>
                        <br/>
                        <SnackbarContent
                            message={
                                <span id="client-snackbar">
                        <WarningIcon/>
                                    {"El tiempo de espera aún no ha finalizado, en " + (props.certificationInformation.waitTime - props.certificationInformation.minutesElapsed) + " minutos podrás realizar la certificación"}
                        </span>
                            }
                            style={{backgroundColor: amber[700]}}
                            elevation={4}
                        />
                    </div>

                );
            } else {
                certificationArea = (
                    <div>
                        <CertificateDetail
                            certificationId={props.certificationId}
                            openSnackbar={props.openSnackbar}
                            handleCloseSnackbar={props.handleCloseSnackbar}
                            userId={props.user}
                            isCertifiedByDates={props.certificationInformation.isCertified && !props.certificationInformation.isCertifiedByDates}
                            handleCloseFullScreenDialog={props.handleCloseCertificationDetail}
                            handleUpdateInformation={props.handleUpdateInformation}
                        />
                    </div>
                );
            }
        }
    }
    return (
        <FullScreenDialog open={props.isOpenDetail}
                          title={"Detalle de certificación"}
                          isSaveButtonshow={false}
                          handleCloseFullScreenDialog={props.handleCloseCertificationDetail}
        >
            <LoaderFullscreen active={props.isLoading}
                              text={"Cargando..."}
                              child={
                                  <Grow in={!props.isLoading}>
                                      <div className={"mt-4 mr-4 ml-4"}>
                                          <GeneralDetail
                                              certificationInformation={props.certificationInformation}
                                              projects={props.projects}
                                              isCertified={isCertified}
                                          />
                                          {certificationArea}
                                      </div>
                                  </Grow>
                              }
            />
        </FullScreenDialog>
    )
}

export default CertificationDetail;
