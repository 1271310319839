import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Chip from "@material-ui/core/Chip";

function RadioTypeQuestion(props){
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend" style={{fontWeight:"bold", color:"#000", fontSize:"16px"}}>
                <Chip variant="outlined" color="primary" label={props.question.alias} size={"small"}/>
                {"  " + props.question.text}
            </FormLabel>
            <RadioGroup aria-label={props.question.alias} value={props.value} name={props.question.alias} onChange={props.handleChange}>
                {
                    props.question.answers.map((answer) => {
                        return (
                            <FormControlLabel
                                key={"k-" + answer.id}
                                value={String(answer.id)}
                                control={<Radio color="primary" />}
                                label={answer.text}
                                labelPlacement="end"
                            />
                        );
                    })
                }
            </RadioGroup>
        </FormControl>
    )
}
export default RadioTypeQuestion;
