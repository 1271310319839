import React from 'react';
import Grow from "@material-ui/core/Grow";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import WorkIcon from '@material-ui/icons/Work';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CommentIcon from '@material-ui/icons/Comment';
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import getStatusText from "../../../util/Commons";
import EventIcon from '@material-ui/icons/Event';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import MessageIcon from '@material-ui/icons/Message';

function DetailInformation(props) {
    return (
        <Grow in={props.information.show}>
            <Card className={"mt-4 ml-3"}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            i
                        </Avatar>
                    }
                    title="Información de solicitud"
                    subheader={props.information.applicationDate + " - " + getStatusText(props.information.status)}
                />
                <CardContent>
                    <div className={"row col-md-12"}>
                        <ListItem component={ListItem}>
                            <ListItemAvatar>
                                <WorkIcon/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={props.information.projectName === undefined ? "" : props.information.projectName.toUpperCase()}
                                secondary={props.information.projectId}/>
                        </ListItem>
                    </div>
                    <div className={"row col-md-12"}>
                        <ListItem component={ListItem}>
                            <ListItemAvatar>
                                <LocationOnIcon/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={props.information.location === undefined ? "" : props.information.location.toUpperCase()}
                                secondary={props.information.locationId}/>
                        </ListItem>
                    </div>
                    <div className={"row col-md-12"}>
                        <ListItem component={ListItem}>
                            <ListItemAvatar>
                                <PersonIcon/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={props.information.upaxer === undefined ? "" : props.information.upaxer.toUpperCase()}
                                secondary={props.information.upaxerUsr}/>
                        </ListItem>
                    </div>
                    <div className={"row col-md-12"}>
                        <ListItem component={ListItem}>
                            <ListItemAvatar>
                                <PermDeviceInformationIcon/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={props.information.taskName === undefined ? "" : props.information.taskName.toUpperCase()}
                                secondary={"TAREA"}/>
                        </ListItem>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <ListItem component={ListItem}>
                                <ListItemAvatar>
                                    <CalendarTodayIcon/>
                                </ListItemAvatar>
                                <ListItemText primary={props.information.auditDate}
                                              secondary={"Fecha de auditoria"}/>
                            </ListItem>
                        </div>
                        <div className={"col-md-6"}>
                            <ListItem component={ListItem}>
                                <ListItemAvatar>
                                    <CalendarTodayIcon/>
                                </ListItemAvatar>
                                <ListItemText primary={props.information.confirmDate}
                                              secondary={"Fecha de confirmación"}/>
                            </ListItem>
                        </div>
                    </div>
                    <div className={"row col-md-12"}>
                        <ListItem component={ListItem}>
                            <ListItemAvatar>
                                <CommentIcon/>
                            </ListItemAvatar>
                            <ListItemText primary={"Comentario de solicitud"}
                                          secondary={props.information.requestComment}
                                          style={{whiteSpace:"pre-wrap"}}/>
                        </ListItem>
                    </div>
                    <div className={"row"} hidden={props.information.status < 2}>
                        <div className={"col-md-6"}>
                            <ListItem component={ListItem}>
                                <ListItemAvatar>
                                    <VerifiedUserIcon/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={props.information.userFullNameAttention}
                                    secondary={props.information.userIdAttention}
                                    title={"Atendido por"}
                                />
                            </ListItem>
                        </div>
                        <div className={"col-md-6"}>
                            <ListItem component={ListItem}>
                                <ListItemAvatar>
                                    <EventIcon/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={props.information.attentionDate}
                                    secondary={"Fecha de atención"}
                                />
                            </ListItem>
                        </div>
                    </div>
                    <div className={"row col-md-12"} hidden={props.information.status < 2}>
                        <ListItem component={ListItem}>
                            <ListItemAvatar>
                                <MessageIcon/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={"Comentario de atención"}
                                secondary={props.information.attentionComment}
                                style={{whiteSpace:"pre-wrap"}}
                            />
                        </ListItem>
                    </div>
                </CardContent>
            </Card>
        </Grow>
    )
}

export default DetailInformation;
