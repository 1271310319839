import {makeStyles} from "@material-ui/core/index";
import {green} from "@material-ui/core/colors/index";

const fullScreenDialogStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    appBar: {
        position: 'stick'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));
export default fullScreenDialogStyles;
