import React from 'react';
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import kGridWithState from "../../util/k-grid";
import Constants from "../../../util/Constants";
import Services from "../../../services/certifications/Services";
import LoaderFullscreen from "../../util/loader/screen";
import CertificationDetail from "../detail/container";
import SnackbarContentWrapper from "../../util/common/snack-bar";
import Snackbar from "@material-ui/core/Snackbar";
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import IconButton from "@material-ui/core/IconButton";

class CertificationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            user: props.user,
            loadPage: false,
            isOpenDetail: false,
            currentCertificationId: 0,
            isLoadingDetail: false,
            certificationInformation: {},
            projects: [],
            openSnackbar: false,
            messageSnackbar: "",
            typeSnackbar: "error",
        };
    }

    componentDidMount() {
        this.setState({loadPage: true});
        this.getCertifications();
    }

    getCertifications = () => {
        let url = "/certifications/" + this.state.user;
        Services.get(url)
            .then(response => {
                let code = response.code;
                if (code === 200) {
                    let certifications = JSON.parse(response.data);
                    this.setState({items: certifications});
                } else {

                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loadPage: false}))
    };

    handleOpenCertificationDetail = (props) => {
        this.setState(
            {
                isLoadingDetail: true,
                isOpenDetail: true,
                currentCertificationId: props.dataItem.certificationId
            }
        );

        let url = "/certifications/" + this.state.user + "/" + props.dataItem.certificationId;
        Services.get(url)
            .then(response => {
                let code = response.code;
                if (code === 200) {
                    let certifications = JSON.parse(response.data);
                    if (certifications.length > 0) {
                        let certificationInformation = certifications[0];
                        let projects = JSON.parse(certificationInformation.projects);
                        this.setState(
                            {
                                projects: projects,
                                certificationInformation: certificationInformation
                            }
                        );
                    }
                } else {
                    alert("erorr")
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoadingDetail: false}));
    };

    handleCloseCertificationDetail = () => {
        this.setState(
            {
                isOpenDetail: false
            }
        );
        this.componentDidMount();
    };

    openSnackbar = (message, type) => {
        this.setState({
            openSnackbar: true,
            typeSnackbar: type,
            messageSnackbar: message
        });
    };

    handleCloseSnackbar = () => {
        this.setState({
            openSnackbar: false
        });
    };

    handleUpdateInformation = (certificationDate, score) => {
        let currentInfo = this.state.certificationInformation;
        currentInfo.certificationDate = certificationDate;
        currentInfo.score = score;
        currentInfo.fromScore = true;
        currentInfo.isCertifiedByDates = false;
        if (score < Constants.MINIMUM_PASSING_GRADE) {
            currentInfo.attempted = this.state.certificationInformation.attempted + 1;
        }
        this.setState({
            certificationInformation: currentInfo
        });
    };

    render() {
        let columns = [];
        columns.push(<Column key="projectName"
                             field="projectName"
                             title="Proyecto"
                             filter="text"
        />);
        columns.push(<Column key="certification"
                             field="certificationName"
                             title="Certificación"
                             filter="text"
        />);
        columns.push(<Column key="score"
                             field="score"
                             title="Calificación"
                             filter="text"
                             width={"180px"}
                             filterable={false}
                             className={"text-center"}
                             cell={
                                 props => {
                                     let item = props.dataItem;
                                     if (item.isCertified && !item.isCertifiedByDates) {
                                         return (
                                             <td className="k-command-cell text-center">
                                                 <del>{item.score}</del>
                                             </td>
                                         )
                                     } else {
                                         return (
                                             <td className="k-command-cell text-center">
                                                 {item.certificationDate === '-' ? '-' : item.score}
                                             </td>
                                         )
                                     }
                                 }
                             }

        />);
        columns.push(<Column key="certificationDate"
                             field="certificationDate"
                             title="Fecha de certificación"
                             filter="text" width={"220px"}
                             filterable={false}
                             className={"text-center"}
                             cell={
                                 props => {
                                     let item = props.dataItem;
                                     if (item.isCertified && !item.isCertifiedByDates) {
                                         return (
                                             <td className="k-command-cell text-center">
                                                 <del>{item.certificationDate}</del>
                                             </td>
                                         )
                                     } else {
                                         return (
                                             <td className="k-command-cell text-center">
                                                 {item.certificationDate}
                                             </td>
                                         )
                                     }
                                 }
                             }
        />);
        columns.push(<Column key="isCertified"
                             cell={
                                 props => {
                                     let item = props.dataItem;
                                     let color;
                                     if (item.isCertified && item.isCertifiedByDates) {
                                         color = 'green'
                                     } else {
                                         color = 'red';
                                     }
                                     return (
                                         <td className="k-command-cell text-center">
                                             <IconButton color="primary" onClick={() => this.handleOpenCertificationDetail(props)}>
                                                 <NotificationsRoundedIcon style={{color: color}}/>
                                             </IconButton>

                                         </td>
                                     )
                                 }
                             }
                             filterable={false}
                             width={"120px"}
        />);
        let group = [];
        const KendoGridCertifications = kGridWithState(Grid, Constants.defaultTableSetting, this.state.items, columns, group);
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.typeSnackbar}
                        message={this.state.messageSnackbar}
                    />
                </Snackbar>
                <CertificationDetail
                    isOpenDetail={this.state.isOpenDetail}
                    buttonText={"Iniciar certificación"}
                    handleCloseCertificationDetail={this.handleCloseCertificationDetail}
                    certificationId={this.state.currentCertificationId}
                    user={this.state.user}
                    isLoading={this.state.isLoadingDetail}
                    projects={this.state.projects}
                    certificationInformation={this.state.certificationInformation}
                    openSnackbar={this.openSnackbar}
                    handleCloseSnackbar={this.handleCloseSnackbar}
                    handleUpdateInformation={this.handleUpdateInformation}
                />
                <LoaderFullscreen
                    active={this.state.loadPage}
                    text={"Cargando..."}
                    child={<KendoGridCertifications/>}
                />
            </div>
        );
    }

}

export default CertificationList;
