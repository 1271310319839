import React from 'react';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CertificationMaterial from "../material";
import SurveyContainer from "../../survey/container";
import Services from "../../../services/certifications/Services";
import CertificationScore from "../score";

const steps = ['Material de capacitación', 'Realizar evaluación', 'Resultado'];

class CertificationStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            isActiveButtonNextStep: false,
            surveyId: 0
        }
    }

    componentDidMount() {
        let promise = this.getSurveyIdByCertificationId(this.props.certificationId);
        promise.then(response => {
            this.setState({
                surveyId: response.data
            });
        })
            .catch(error => {
                this.props.openSnackbar(error, "error");
            })

    }

    handleNextStep = () => {
        let currentStep = this.state.activeStep;
        this.setState(
            {
                activeStep: currentStep + 1
            }
        )
    };

    handleActivateButtonNextStep = () => {
        this.setState({
            isActiveButtonNextStep: true
        });
    };

    handleDesactivateButtonNextStep = () => {
        this.setState({
            isActiveButtonNextStep: false
        });
    };

    getSurveyIdByCertificationId = (certificationId) => {
        let url = "/certifications/" + certificationId + "/survey";
        return Services.get(url);
    };

    getContentStep = () => {
        let currentStep = this.state.activeStep;
        let content;
        switch (currentStep) {
            case 0:
                content = (
                    <CertificationMaterial
                        certificationId={this.props.certificationId}
                        handleActivateButtonNextStep={this.handleActivateButtonNextStep}
                    />
                );
                break;
            case 1:
                content = (
                    <SurveyContainer
                        surveyId={this.state.surveyId}
                        handleDesactivateButtonNextStep={this.handleDesactivateButtonNextStep}
                        handleActivateButtonNextStep={this.handleActivateButtonNextStep}
                        openSnackbar={this.props.openSnackbar}
                        handleCloseSnackbar={this.props.handleCloseSnackbar}
                        certificationId={this.props.certificationId}
                        userId={this.props.userId}
                    />
                );
                break;
            case 2:
                content = (
                    <CertificationScore
                        certificationId={this.props.certificationId}
                        userId={this.props.userId}
                        handleUpdateInformation={this.props.handleUpdateInformation}
                        handleDesactivateButtonNextStep={this.handleDesactivateButtonNextStep}
                        handleActivateButtonNextStep={this.handleActivateButtonNextStep}
                    />
                );
                break;
            default:
                content = 'Unknown';
                break;
        }
        return content;
    };

    render() {
        return (
            <div className={"p-2"}>
                <Stepper activeStep={this.state.activeStep} alternativeLabel style={{padding: "5px"}}>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {
                        this.state.activeStep === steps.length ? (
                            <div>
                                <Typography>All steps completed</Typography>
                            </div>
                        ) : (
                            <div>
                                <div style={{minHeight: "320px", marginBottom:"10px", backgroundColor: "#fafafa"}} className={"card"}>
                                    <div className="card-body">{this.getContentStep()}</div>
                                </div>
                                <div className={"text-right"}>
                                    <Button variant="contained"
                                            color="primary"
                                            onClick={this.state.activeStep === steps.length - 1 ? this.props.handleCloseFullScreenDialog : this.handleNextStep}
                                            disabled={!this.state.isActiveButtonNextStep}
                                    >
                                        {this.state.activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

}

export default CertificationStepper;
