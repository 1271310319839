import React from 'react';
import Typography from "@material-ui/core/Typography";

function LabelTypeQuestion({text}) {
    return(
        <Typography variant="h5" component="h2">
            {text}
        </Typography>
    );
}

export default LabelTypeQuestion;
