import React from 'react';
import CertificationStepper from "../../stepper";
import {amber} from "@material-ui/core/colors";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from '@material-ui/icons/Warning';

function CertificateDetail(props) {
    return (
        <div className="card mt-4 mb-2">
            <div className="card-header">Certificate: Completa los siguientes pasos para certificarte</div>
            <div className="card-body">
                <div hidden={!props.isCertifiedByDates}>
                    <SnackbarContent
                        message={
                            <span id="client-snackbar">
                        <WarningIcon/>
                                {"La certificación han sido modificada, es necesario renovar tu cerficación"}
                        </span>
                        }
                        style={{backgroundColor: amber[700]}}
                        elevation={4}
                    />
                </div>
                <CertificationStepper
                    certificationId={props.certificationId}
                    openSnackbar={props.openSnackbar}
                    handleCloseSnackbar={props.handleCloseSnackbar}
                    userId={props.userId}
                    handleCloseFullScreenDialog={props.handleCloseFullScreenDialog}
                    handleUpdateInformation={props.handleUpdateInformation}
                />
            </div>
        </div>
    );
}

export default CertificateDetail;
