import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ClarificationsByUser from "./containers/clarifications/ClarificationsByUser"
import AllClarifications from "./containers/clarifications/AllClarifications";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Certifications from "./containers/certifications/Certifications";

const theme = createMuiTheme({
    palette:{
        primary:{
            main: "#ea5b0a"
        }
    }
});

function App() {
    return (
        <MuiThemeProvider theme={theme}>
        <Router>
            <div className="">
                <Switch>
                    <Route path="/clarifications/user/:user" component={ClarificationsByUser}/>
                    <Route path="/clarifications/all/:user" component={AllClarifications}/>
                    <Route path="/certifications/:user" component={Certifications}/>
                </Switch>
            </div>
        </Router>
        </MuiThemeProvider>
    );
}

export default App;
