import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline/index";
import Container from "@material-ui/core/Container/index";
import ClarificationListByUser from '../../components/clarifications/list/by-user';

class ClarificationsByUser extends React.PureComponent {
   render() {
        return (
            <div>
                <React.Fragment>
                    <CssBaseline/>
                    <Container maxWidth="xl" className={"mt-2"}>
                        <ClarificationListByUser user={this.props.match.params.user}/>
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}

export default ClarificationsByUser;
