import React from 'react';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/index";
import IconButton from "@material-ui/core/IconButton/index";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography/index";
import Button from "@material-ui/core/Button/index";
import Dialog from "@material-ui/core/Dialog/index";
import Slide from "@material-ui/core/Slide/index";
import fullScreenDialogStyles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = (props) => {
    const classes = fullScreenDialogStyles();

    return (
        <Dialog fullScreen open={props.open}
                TransitionComponent={Transition}
                style={{zIndex: 998}}
        >
            <AppBar className={classes.appBar}>
                <Toolbar >
                    <IconButton edge="start"
                                color="inherit"
                                aria-label="close"
                                onClick={props.handleCloseFullScreenDialog}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6"
                                className={classes.title}>
                        {props.title}
                    </Typography>
                    {
                        props.isSaveButtonshow ?
                            <Button color="inherit"
                                    onClick={props.handleSave}
                            >
                                {props.saveLabel}
                            </Button>
                            : ""
                    }
                    <CircularProgress color={"inherit"} hidden={!props.isSaving}/>
                </Toolbar>
            </AppBar>
            <div className={"pt-5"}>
                {props.children}
            </div>
        </Dialog>
    );
};

export default FullScreenDialog;
