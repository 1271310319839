import React from 'react';
import ClarificationService from "../../../../services/clarifications/ClarificationService";
import LoaderFullscreen from "../../../util/loader/screen"
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import DetailCell from "../../../util/custom-cell/detail";
import kGridWithState from "../../../util/k-grid";
import Constants from "../../../../util/Constants";
import FullScreenDialog from "../../../util/dialog/full-screen";
import DetailInformation from "../../information";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from "../../../util/common/snack-bar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Grow from "@material-ui/core/Grow";
import PermMediaIcon from '@material-ui/icons/PermMedia';
import LinearProgress from "@material-ui/core/LinearProgress";


class ClarificationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            loadPage: false,
            openFullScreenDialog: false,
            showInfo: true,
            isOpenResponseModal: false,
            applyRequest: 0,
            commentRequest: "",
            openSnackbar: false,
            messageSnackbar: "",
            typeSnackbar: "error",
            clarificationConsulted: 0,
            showButtonDialog: true,
            sendingReply: false,
            items: [],
            information: {
                assignmentId: '',
                projectId: '',
                projectName: '',
                project: '',
                locationId: '',
                location: '',
                upaxerId: '',
                upaxer: '',
                tasks: [],
            }
        };
    }

    componentDidMount() {
        this.setState({loadPage: true});
        this.getAllClarifications();
    }

    getAllClarifications = () => {
        let url = "/clarifications/all";
        ClarificationService.get(url)
            .then(data => {
                let code = data.status;
                if (code === 200) {
                    let clarifications = JSON.parse(data.body.data);
                    this.setState({items: clarifications});
                } else {
                    alert("erorr")
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loadPage: false}))
    };

    handleShowDetail = (props) => {
        this.setState({openFullScreenDialog: true});
        let url = "/clarifications/" + props.dataItem.clarificationId;
        this.setState({clarificationConsulted: props.dataItem.clarificationId});
        ClarificationService.get(url)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    let information = JSON.parse(response.body.data)[Constants.ZERO];
                    information.show = true;
                    information.evidences = JSON.parse(information.evidences);
                    this.setState({
                        information: information
                    });
                    let status = information.status;
                    if (status === 1) {
                        let urlSaveEvent = "/clarifications/watch";
                        let parameters = {
                            clarificationId: props.dataItem.clarificationId,
                            userModified: this.state.user
                        };
                        ClarificationService.put(urlSaveEvent, parameters)
                            .catch(error => {
                                console.log(error)
                            })
                    }
                    if(status === 1 || status === 2){
                        this.setState({showButtonDialog: true});
                    }else {
                        this.setState({showButtonDialog: false});
                    }
                } else {
                    throw Error("Error al cargar los datos");
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loadPage: false}));

    };

    handleCloseFullScreenDialog = () => {
        let information = {};
        information.show = false;
        this.setState(
            {
                openFullScreenDialog: false,
                information: information,
                isOpenResponseModal: false,
                applyRequest: 0,
                commentRequest: "",
            }
        );
        this.componentDidMount();
    };

    handleOpenResponseModal = () => {
        this.setState({
            isOpenResponseModal: true
        });
    };
    handleCloseResponseModal = () => {
        this.setState({
            isOpenResponseModal: false,
            applyRequest: 0,
            commentRequest: "",
        });
    };

    handleChangeOptionModal = event => {
        this.setState({
            applyRequest: event.target.value
        });
    };

    handleChangeCommentRequest = event => {
        this.setState({
            commentRequest: event.target.value
        })
    };

    handleAcceptRequest = async () => {
        if (this.state.applyRequest === 0) {
            this.openSnackbar("Selecciona una opción", "error");
        } else if (this.state.commentRequest.trim().length < 1) {
            this.openSnackbar("Ingresa un comentario", "error");
        } else {
            try {
                this.setState({sendingReply:true});
                let response = await this.updateStatusClarification();
                if (this.state.applyRequest === "3") {
                    let resp = await this.updateAssignmentToAudit(response.body.data);
                    if (resp.status !== 200) {
                        this.openSnackbar("Ocurrió un error al responder la solicitud", "error");
                    } else {
                        this.handleCloseResponseModal();
                        this.updateDetail();
                        this.openSnackbar("La solicitud fue respondida correctamente", "success");
                    }
                } else {
                    this.handleCloseResponseModal();
                    this.updateDetail();
                    this.openSnackbar("La solicitud fue respondida correctamente", "success");
                }
            } catch (e) {
                this.openSnackbar("Ocurrió un error al responder la solicitud", "error");
            }finally {
                this.setState({sendingReply: false});
            }
        }

    };

    updateStatusClarification = () => {
        let url = "/clarifications/answer";
        let parameters = {
            clarificationId: this.state.clarificationConsulted,
            status: this.state.applyRequest,
            comment: this.state.commentRequest,
            userModified: this.state.user
        };
        return ClarificationService.put(url, parameters);
    };

    updateAssignmentToAudit = (detailTaskId) => {
        let url = "/clarifications/task/free";
        let parameters = {
            detailTaskId: detailTaskId,
            userModified: this.state.user
        };
        return ClarificationService.put(url, parameters);
    };

    handleCloseSnackbar = () => {
        this.setState({
            openSnackbar: false
        });
    };

    openSnackbar = (message, type) => {
        this.setState({
            openSnackbar: true,
            typeSnackbar: type,
            messageSnackbar: message
        });
    };

    updateDetail= () => {
        this.setState({loadPage: true});
        let url = "/clarifications/" + this.state.clarificationConsulted;
        ClarificationService.get(url)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    let information = JSON.parse(response.body.data)[Constants.ZERO];
                    information.show = true;
                    information.evidences = JSON.parse(information.evidences);
                    this.setState({
                        information: information
                    });
                    let status = information.status;
                    if(status === 1 || status === 2){
                        this.setState({showButtonDialog: true});
                    }else {
                        this.setState({showButtonDialog: false});
                    }
                } else {
                    throw Error("Error al cargar los datos");
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loadPage: false}));
    };

    render() {
        let columns = [];
        columns.push(<Column key="assignmentId" field="assignmentId" title="Asignación" filter="numeric"/>);
        columns.push(<Column key="projectName" field="projectName" title="Proyecto" filter="text"/>);
        columns.push(<Column key="location" field="location" title="Ubicación" filter="text"/>);
        columns.push(<Column key="taskName" field="taskName" title="Tarea" filter="text"/>);
        columns.push(<Column key="applicantFullName" field="applicantFullName" title="Solicitante" filter="text"/>);
        columns.push(<Column key="applicationDate" field="applicationDate" title="Fecha de solicitud" filter="text"/>);
        columns.push(<Column key="status" field={"statusText"} title="Estatus" filter="text"/>);
        columns.push(<Column cell={DetailCell(this.handleShowDetail)} filterable={false} width={"100px"}/>);
        let tableSetting = {
            sortable: true,
            filterable: true,
            reorderable: true,
            groupable: false
        };

        let group = [];
        const KendoGrid = kGridWithState(Grid, tableSetting, this.state.items, columns, group);
        let gallery;
        if (this.state.information.show) {
            if (this.state.information.evidences.length > Constants.ZERO) {
                gallery = (
                        <ImageGallery items={this.state.information.evidences}
                                      showFullscreenButton={false}
                                      thumbnailPosition={"right"}
                                      showPlayButton={false}
                        />
                );
            } else {
                gallery = (
                        <p>
                            No hay evidencias cargadas
                        </p>
                );
            }
        }
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.typeSnackbar}
                        message={this.state.messageSnackbar}
                    />
                </Snackbar>
                <Dialog open={this.state.isOpenResponseModal}
                        onClose={this.handleCloseResponseModal}
                        aria-labelledby="form-dialog-title"
                        maxWidth={"md"}
                        fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">Responder solicitud</DialogTitle>
                    <DialogContent>
                        <LinearProgress hidden={!this.state.sendingReply}/>
                        <DialogContentText>
                            Selecciona una opción y agrega un comentario
                        </DialogContentText>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">¿Aplica la solicitud?</FormLabel>
                            <RadioGroup aria-label="Aplica" name="aplica" value={this.state.applyRequest}
                                        onChange={this.handleChangeOptionModal}>
                                <FormControlLabel value="3" control={<Radio/>} label="Sí"/>
                                <FormControlLabel value="4" control={<Radio/>} label="No"/>
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            key={"comment-response"}
                            label="Cometario"
                            multiline
                            rows="8"
                            value={this.state.commentRequest}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChangeCommentRequest}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseResponseModal} color="secondary" hidden={this.state.sendingReply}>
                            Cancelar
                        </Button>
                        <Button onClick={this.handleAcceptRequest} color="primary" hidden={this.state.sendingReply}>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>

                <FullScreenDialog open={this.state.openFullScreenDialog}
                                  title={"Detalle de aclaración"}
                                  isSaveButtonshow={this.state.showButtonDialog}
                                  saveLabel={"Responder"}
                                  handleSave={this.handleOpenResponseModal}
                                  handleCloseFullScreenDialog={this.handleCloseFullScreenDialog}
                >
                    <LoaderFullscreen active={!this.state.information.show}
                                      text={"Cargando..."}
                                      child={
                                          <div className={"row"}>
                                              <div className={"col-md-4"}>
                                                  <DetailInformation
                                                      information={this.state.information}
                                                  />
                                              </div>
                                              <div className={"col-md-8"}>
                                                  <Grow in={this.state.information.show}>
                                                      <Card className={"mt-4"}>
                                                          <CardHeader
                                                              avatar={
                                                                  <Avatar aria-label="recipe" component={Avatar} >
                                                                      <PermMediaIcon/>
                                                                  </Avatar>
                                                              }
                                                              title="Evidencias"
                                                              subheader="Lista de archivos de evidencia"
                                                          />
                                                          <CardContent>
                                                              {gallery}
                                                          </CardContent>
                                                      </Card>
                                                  </Grow>

                                              </div>
                                          </div>
                                      }
                    />
                </FullScreenDialog>
                <LoaderFullscreen active={this.state.loadPage}
                                  text={"Cargando..."}
                                  child={<KendoGrid/>}
                />
            </div>
        );
    }

}

export default ClarificationsList;
