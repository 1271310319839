import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import medal from "../../../../static/images/medal-certificate.png";

function GeneralDetail(props) {
    let attempted = props.certificationInformation.attempts - props.certificationInformation.attempted;
    return (
        <div>
            <div className="card">
                <div className="card-header">Información general de la certificación</div>
                <div className="card-body">
                    <img style={{width: "120px", height: "150px"}} src={medal} alt={"Aprobado"} className={"float-right"} hidden={!props.isCertified}/>
                    <div className={"row"}>
                        <div className={"col-md-4"}>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemText
                                        primary={props.certificationInformation.certificationName}
                                        secondary={"Certificación"}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.certificationInformation.certificationDescription}
                                        secondary={"Descripción"}
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <div className={"col-md-4"}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <ListItem dense={true}>
                                        <ListItemText
                                            primary={props.certificationInformation.attempts}
                                            secondary={"Intentos permitidos"}
                                        />
                                    </ListItem>
                                </div>
                                <div className={"col-md-6"}>
                                    <ListItem dense={true}>
                                        <ListItemText
                                            primary={"" + attempted}
                                            secondary={"Intentos restantes"}
                                        />
                                    </ListItem>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <ListItem dense={true}>
                                        <ListItemText
                                            primary={
                                                props.certificationInformation.isCertified ? (
                                                    props.certificationInformation.isCertifiedByDates ? props.certificationInformation.certificationDate :
                                                        <del>{props.certificationInformation.certificationDate}</del>
                                                ) : (props.certificationInformation.certificationDate === '-' ? (<span style={{color: "red"}}>No certificado</span>) : props.certificationInformation.certificationDate)
                                            }
                                            secondary={"Fecha de evaluación"}
                                        />
                                    </ListItem>
                                </div>
                                <div className={"col-md-6"}>
                                    <ListItem dense={true}>
                                        <ListItemText
                                            primary={props.certificationInformation.isCertified ? (
                                                props.certificationInformation.isCertifiedByDates ? props.certificationInformation.score :
                                                    <del>{props.certificationInformation.score}</del>
                                            ) : (props.certificationInformation.certificationDate === '-' ? (<span style={{color: "red"}}>No certificado</span>) : props.certificationInformation.score)}
                                            secondary={"Calificación"}
                                        />
                                    </ListItem>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-4"}>
                            <List dense={true}
                                  subheader={"Lista de proyectos para los que aplica la certificación"}>
                                {
                                    props.projects.map((project, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <ListItemText
                                                    primary={project.projectName}
                                                    secondary={project.projectId}
                                                />
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralDetail;
