import React from 'react';
import {GridCell} from '@progress/kendo-react-grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

function DetailCell(open) {
    return class extends GridCell {
        render() {
            return (
                <td className="k-command-cell">
                    <IconButton color="primary" onClick={() => open(this.props)}>
                        <VisibilityIcon/>
                    </IconButton>
                </td>
            );
        }
    }
}

export default DetailCell;
