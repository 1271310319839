import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import BackupIcon from '@material-ui/icons/Backup';
import VisibilityIcon from '@material-ui/icons/Visibility';

function FileList({title, subtitle, files, id, onLoadFile, onDelete, onVisualize}) {
    return (
        <List>
            <ListItem>
                <ListItemText
                    primary={title}
                    secondary={subtitle}/>
                <ListItemSecondaryAction>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        id={"raised-button-file" + id}
                        type="file"
                        onChange={onLoadFile(id)}
                    />
                    <label htmlFor={"raised-button-file" + id}>
                        <IconButton disabled={false}
                                    component="span"
                                    color="primary">
                            <BackupIcon/>
                        </IconButton>
                    </label>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider/>
            {files.map((file, index) => {
                return (
                    <div>
                        <ListItem key={index}
                                  role={undefined} dense
                                  button>
                            <ListItemText
                                primary={file.name}/>
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={onDelete(id, index)}>
                                    <DeleteIcon/>
                                </IconButton>
                                <IconButton edge="end" onClick={onVisualize(id, index)}>
                                    <VisibilityIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                    </div>
                )
            })}
        </List>
    )
}

export default FileList;
