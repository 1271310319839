import React from "react";
import LoadingScreen from 'react-loading-screen'

export default function LoaderFullscreen({active, text, child}) {
    return (
        <LoadingScreen
            loading={active}
            bgColor='#f1f1f1'
            spinnerColor='#ff6138'
            textColor='#4b4a4b'
            logoSrc='https://topobucket.s3.amazonaws.com/imagenes/logoupaxer.png'
            text={text}
        >
            {child}
        </LoadingScreen>
    )
}
