import React from 'react';
import {Grid} from "@progress/kendo-react-grid/dist/npm/main";
import kGridWithState from '../../../util/k-grid';
import ClarificationService from "../../../../services/clarifications/ClarificationService";
import {GridColumn as Column} from "@progress/kendo-react-grid";
import DetailCell from "../../../util/custom-cell/detail";
import FullScreenDialog from "../../../util/dialog/full-screen";
import LoaderFullscreen from "../../../util/loader/screen";
import DetailInformation from "../../information";
import Constants from "../../../../util/Constants";
import Grow from "@material-ui/core/Grow";
import Avatar from "@material-ui/core/Avatar";
import ImageGallery from "react-image-gallery";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import PermMediaIcon from '@material-ui/icons/PermMedia';
import CardContent from "@material-ui/core/CardContent";
import AddClarification from "../../add";

class ClarificationListByUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadPage: false,
            openFullScreenDialog: false,
            showInfo: true,
            clarificationConsulted: 0,
            items: [],
            information: {
                show: false,
                assignmentId: '',
                projectId: '',
                project: '',
                locationId: '',
                location: '',
                upaxerId: '',
                upaxer: '',
                tasks: [],
            }
        };
    }

    componentDidMount() {
        this.getClarificationListByUser(this.props.user);
    }

    getClarificationListByUser = (user) => {
        this.setState({loadPage: true});
        let url = "/clarifications/user/" + user;
        ClarificationService.get(url)
            .then(data => {
                let code = data.status;
                if (code === 200) {
                    let clarifications = JSON.parse(data.body.data);
                    this.setState({items: clarifications});
                } else {
                    alert("erorr")
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loadPage: false}));
    };

    handleShowDetail = (props) => {
        this.setState({openFullScreenDialog: true});
        let url = "/clarifications/" + props.dataItem.clarificationId;
        ClarificationService.get(url)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    let information = JSON.parse(response.body.data)[Constants.ZERO];
                    information.show = true;
                    information.evidences = JSON.parse(information.evidences);
                    this.setState({
                        information: information
                    });
                } else {

                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loadPage: false}));

    };

    handleCloseFullScreenDialog = () => {
        let information = {};
        information.show = false;
        this.setState(
            {
                openFullScreenDialog: false,
                information: information
            }
        );
    };

    render() {
        let columns = [];
        columns.push(<Column key="assignmentId" field="assignmentId" title="Asignación" filter="numeric"
                             width={"200px"}/>);
        columns.push(<Column key="projectName" field="projectName" title="Proyecto" filter="text"/>);
        columns.push(<Column key="location" field="location" title="Ubicación" filter="text"/>);
        columns.push(<Column key="taskName" field="taskName" title="Tarea" filter="text"/>);
        columns.push(<Column key="requestComment" field="applicationDate" title="Fecha de solicitud" filter="text"/>);
        columns.push(<Column key="status" field={"statusText"} title="Estatus" filter="text"/>);
        columns.push(<Column key={"detail"} cell={DetailCell(this.handleShowDetail)} filterable={false} width={"100px"}/>);
        let tableSetting = {
            sortable: true,
            filterable: true,
            reorderable: true,
            groupable: false
        };
        let group = [];
        const KendoGrid = kGridWithState(Grid, tableSetting, this.state.items, columns, group);
        let gallery;
        if (this.state.information.show) {
            if (this.state.information.evidences.length > Constants.ZERO) {
                gallery = (
                    <ImageGallery items={this.state.information.evidences}
                                  showFullscreenButton={false}
                                  thumbnailPosition={"right"}
                                  showPlayButton={false}

                    />
                );
            } else {
                gallery = (
                    <p>
                        No hay evidencias cargadas
                    </p>
                );
            }
        }
        return (
            <div>

                <AddClarification user={this.props.user} handleLoadData={this.getClarificationListByUser}/>

                <FullScreenDialog open={this.state.openFullScreenDialog}
                                  title={"Detalle de aclaración"}
                                  isSaveButtonshow={false}
                                  handleCloseFullScreenDialog={this.handleCloseFullScreenDialog}
                >
                    <LoaderFullscreen active={!this.state.information.show}
                                      text={"Cargando..."}
                                      child={
                                          <div className={"row"}>
                                              <div className={"col-md-4"}>
                                                  <DetailInformation
                                                      information={this.state.information}
                                                  />
                                              </div>
                                              <div className={"col-md-8"}>
                                                  <Grow in={this.state.information.show}>
                                                      <Card className={"mt-4"}>
                                                          <CardHeader
                                                              avatar={
                                                                  <Avatar aria-label="recipe" component={Avatar}>
                                                                      <PermMediaIcon/>
                                                                  </Avatar>
                                                              }
                                                              title="Evidencias"
                                                              subheader="Lista de archivos de evidencia"
                                                          />
                                                          <CardContent>
                                                              {gallery}
                                                          </CardContent>
                                                      </Card>
                                                  </Grow>
                                              </div>
                                          </div>
                                      }
                    />
                </FullScreenDialog>
                <LoaderFullscreen active={this.state.loadPage}
                                  text={"Cargando..."}
                                  child={<KendoGrid/>}
                />
            </div>
        );
    }
}

export default ClarificationListByUser;
