import React from 'react';
import MobileStepper from "@material-ui/core/MobileStepper/index";
import Button from "@material-ui/core/Button/index";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons/index";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import Services from "../../../services/certifications/Services";
import RadioTypeQuestion from "../questions/types/radio";
import Slide from "@material-ui/core/Slide";
import LabelTypeQuestion from "../questions/types/label";


class SurveyContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            isLoadingQuestion: false,
            isLoadingHeaderSurvey: false,
            survey: {
                id: 0,
                name: "",
                totalQuestions: 0
            },
            question: {
                id: 0,
                sequence: 0,
                type: '',
                text: '',
                control: '',
                alias: '',
                answers: []

            },
            selectedAnswer: '',
            questions: [],
            isFinalPage: false,
            previusError: false
        }
    }

    handleChangeRadioQuestion = event => {
        this.setState({
            selectedAnswer: event.target.value
        });
    };

    componentDidMount() {
        this.props.handleDesactivateButtonNextStep();
        this.setState({
            isLoadingQuestion: true,
            isLoadingHeaderSurvey: true
        });
        this.getSurveyInformation();
        this.getQuestion();
    }

    getSurveyInformation = () => {
        let url = "/survey/" + this.props.surveyId;
        Services.get(url)
            .then(response => {
                let code = response.code;
                if (code === 200) {
                    let data = JSON.parse(response.data);
                    this.setState({
                        survey: {
                            id: data.surveyId,
                            name: data.surveyName,
                            totalQuestions: data.totalQuestions
                        }
                    });
                } else {
                    alert("erorr")
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoadingHeaderSurvey: false}));
    };

    getQuestion = () => {
        let url = "/survey/" + this.props.surveyId + "/questions/" + this.state.question.id;
        Services.get(url)
            .then(response => {
                let code = response.code;
                if (code === 200) {
                    let data = JSON.parse(response.data);
                    this.setState({
                        question: {
                            id: data.questionId,
                            sequence: data.sequence,
                            type: data.questionType,
                            text: data.questionText,
                            control: data.controlType,
                            alias: data.alias,
                            answers: JSON.parse(data.answers)
                        },
                        selectedAnswer: ''
                    });
                } else {
                    alert("erorr")
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoadingQuestion: false}));
    };

    handleNextQuestion = () => {
        if (this.state.selectedAnswer === '') {
            this.props.openSnackbar("La pregunta es obligatoria", "error");
        } else {
            this.setQuestionInArray();
            this.setState({
                activeStep: this.state.activeStep + 1,
                isLoadingQuestion: true
            });
            this.getQuestion();
        }
    };

    setQuestionInArray = () => {
        let currentQuestions = this.state.questions;
        let question = this.state.question;
        let selectedAnswer = parseInt(this.state.selectedAnswer);
        let today = new Date();
        let day = today.getDate();
        let month = today.getMonth() + 1;
        let year = today.getFullYear();
        let hour = today.getHours();
        let min = today.getMinutes();
        let sec = today.getSeconds();
        question.time = (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec);
        for (let x = 0; x < question.answers.length; x++) {
            question.answers[x].selected = (question.answers[x].id === selectedAnswer);
        }
        currentQuestions.push(question);
        this.setState({
            questions: currentQuestions
        });
    };

    handleFinalize = () => {
        if(!this.state.previusError){
            if (this.state.selectedAnswer === '') {
                this.props.openSnackbar("La pregunta es obligatoria", "error");
            } else {
                this.setQuestionInArray();
                let answeredQuestions = {
                    surveyId: this.state.survey.id,
                    userId: this.props.userId,
                    certificationId: this.props.certificationId,
                    questions: this.state.questions
                };
                this.setState({
                    isLoadingQuestion: true
                });
                let url = "/survey/answered";
                Services.post(url, answeredQuestions)
                    .then(response => {
                        let code = response.code;
                        if (code === 200) {
                            this.setState({
                                isFinalPage: true
                            });
                            this.props.handleActivateButtonNextStep();
                        } else {
                            this.setState({previusError: true});
                            this.props.openSnackbar(response.message, "error");
                        }
                    })
                    .catch(error => {
                        this.props.openSnackbar("Ocurrió un error por favor contacta a soporte: " + error, "error");
                        this.setState({previusError: true});
                    })
                    .finally(() => this.setState({isLoadingQuestion: false}));
            }
        }else {
            this.props.openSnackbar("Ocurrió un error por favor contacta a soporte", "error");
        }

    };

    handleBackQuestion = () => {
        let question = this.state.questions[this.state.activeStep - 1];
        let selectedAnswerValue = '';
        for (let x = 0; x < question.answers.length; x++) {
            if (question.answers[x].selected) {
                selectedAnswerValue = String(question.answers[x].id)
            }
        }
        let currentQuestions = this.state.questions;
        currentQuestions.splice(this.state.activeStep - 1, 1);
        this.setState({
            activeStep: this.state.activeStep - 1,
            question: question,
            selectedAnswer: selectedAnswerValue,
            questions: currentQuestions
        });
    };

    getQuestionComponent() {
        if (this.state.isFinalPage) {
            return (
                <LabelTypeQuestion
                    text={"Las evaluación fue finalizada correctamente, para conocer el resultado presiona el botón siguiente."}
                />
            );
        } else {
            return (
                <RadioTypeQuestion
                    handleChange={this.handleChangeRadioQuestion}
                    question={this.state.question}
                    value={this.state.selectedAnswer}
                />
            );
        }

    }

    render() {
        let questionComponent = "";
        if (!this.state.isLoadingQuestion || this.state.isFinalPage) {
            questionComponent = this.getQuestionComponent();
        }
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Slide direction="left" mountOnEnter unmountOnExit in={!this.state.isLoadingHeaderSurvey}>
                            <div>
                                <h5 style={{color: "#ff592a"}}>{this.state.survey.name}</h5>
                                <MobileStepper
                                    variant="progress"
                                    steps={this.state.survey.totalQuestions}
                                    position="static"
                                    activeStep={this.state.activeStep}
                                    nextButton={
                                        (
                                            this.state.activeStep === this.state.survey.totalQuestions - 1 ?
                                                <Button size="small"
                                                        onClick={this.handleFinalize}
                                                        disabled={this.state.isLoadingQuestion || this.state.isFinalPage}
                                                        variant="outlined"
                                                        color="primary"
                                                >
                                                    Finalizar
                                                </Button> :
                                                <Button size="small"
                                                        onClick={this.handleNextQuestion}
                                                        disabled={this.state.isLoadingQuestion}
                                                        hidden={this.state.activeStep >= this.state.survey.totalQuestions - 1}
                                                        variant="outlined"
                                                        color="primary"
                                                >
                                                    Siguiente
                                                    <KeyboardArrowRight/>
                                                </Button>
                                        )

                                    }
                                    backButton={
                                        <Button size="small" onClick={this.handleBackQuestion}
                                                disabled={this.state.activeStep === 0 || this.state.isLoadingQuestion || this.state.isFinalPage}
                                                hidden={this.state.activeStep > this.state.survey.totalQuestions - 1}
                                                variant="outlined"
                                                color="primary"
                                        >
                                            <KeyboardArrowLeft/>
                                            Anterior
                                        </Button>
                                    }
                                />
                            </div>
                        </Slide>
                    </div>
                </div>
                <br/>
                <div hidden={!this.state.isLoadingQuestion} className={"text-center"} style={{marginTop: "3%"}}>
                    <CircularProgress/>
                </div>
                <Slide direction="left" in={!this.state.isLoadingQuestion} mountOnEnter unmountOnExit>
                    <div>
                        {questionComponent}
                    </div>
                </Slide>

            </div>
        );
    }

}

export default SurveyContainer;
